pre {
	padding: 0 3px 2px;
	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
	color: #333;
	display: block;
	padding: 9.5px;
	margin: 0 0 30px 0 !important;
	font-size: 13px;
	line-height: 20px;
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre;
	white-space: pre-wrap;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	-webkit-border-radius: 4px;
	        border-radius: 4px;
}
pre.prettyprint {
	margin-bottom: 20px;
}
.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}
.com {
	color: #999;
}
.lit {
	color: #195f91;
}
.pun, .opn, .clo {
	color: #93a1a1;
}
.fun {
	color: #dc322f;
}
.str, .atv {
	color: #D44950;
}
.kwd, .prettyprint .tag {
	color: #2F6F9F;
}
.typ, .atn, .dec, .var {
	/*color: teal;*/
	color: #4F9FCF;
}
.pln {
	color: #333;
}
.prettyprint {
	padding: 10px 15px;
	background-color: #F7F8F9;
	border: 1px solid #e1e1e8;
}
.prettyprint.linenums {
	-webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
	        box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
}
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
	margin: 0 0 0 -12px;
}
ol.linenums li {
	padding-left: 12px;
	color: #bebec5;
	line-height: 20px;
	list-style: decimal;
}