.modal-default-style {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: 20%;
    margin-top: 20%;
    transform: translate(10%, -20%);
    background:  #114572;
    z-index: 9999;
}

.consent-background {
    background-image: linear-gradient(to right, #0b6579, black);
    position: absolute;
    right: 0;
    color: #fff;
    padding: 3%;
    max-width: 40%;
    border-radius: 50px;
    z-index: 9990;
}
.consent-background .hidden {
    display: none;
}

.consent-link {
    color: #e7870f;
    font-weight: bold;
    position: absolute;
    left: 10%;
}

.consent-default-button { 
    background-color: #195386;
    border: 2px solid #368bd6;
    border-radius: 10px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer!important;
    font-family: Arial,sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 55px!important;
    letter-spacing: 2px;
    line-height: 34px;
    margin: 10px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
}

.consent-default-button:hover { 
    background-color: #fff;
    border: 2px solid #fff;
    color: #368bd6;
}

.consent-disagree-button { 
    background-color: #88141a;
    border: 2px solid #c13b42;
    border-radius: 10px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer!important;
    font-family: Arial,sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 55px!important;
    letter-spacing: 2px;
    line-height: 34px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    margin: 10px;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
}

.consent-disagree-button:hover { 
    background-color: #fff;
    border: 2px solid #fff;
    color: #88141a;
}


.consent-title {
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    margin: 0 0 24px;
}

.consent-centerPopup-button-area {
    margin-top: 20px;
    text-align: right;
    letter-spacing: -.43em;
    text-rendering: optimizeSpeed;
    zoom: 1;
}

.consent-sticky {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px #000;
    color: #fff;
}
