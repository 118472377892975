/** Code By Webdevtrick ( https://webdevtrick.com ) **/
.gradient-background {
	margin: 0;
	width: 100%;
	height: 100vh;
	font-family: "Exo", sans-serif;
	color: #fff;
	background: linear-gradient(342deg, #3477ce 30%, #1043a3 50%, #02071a 70%);
	background-size: 400% 400%;
	-webkit-animation: gradientBG 12s ease infinite;
	        animation: gradientBG 12s ease infinite;
}

@-webkit-keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}